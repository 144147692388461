<template>
   <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        学习包
      </template>
      <template #input>
        <a-input v-model="queryParams.name" placeholder="请输入学习包名称" allowClear/>
        <a-button
          type="primary"
          class="btn"
          @click="onSearch" 
        >搜索</a-button>
        <a-button
          class="all_boder_btn"
          @click="toDetail(1)"
        >新建</a-button>
      </template>
    </HeaderBox>

      <a-table 
        class="table-template"
        :rowKey="item=>item.id"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
        <!-- 序号 -->
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>
      
      <!-- 活动时间 -->
      <template
        slot="timeType"
        slot-scope="item"
      >
        <span v-if="item.timeType == 1">不限</span>
        <div v-else>
          <p>{{item.startTime}}</p>
          <p>{{item.endTime}}</p>
        </div>
      </template>

      <!-- 终端展示 -->
      <template slot="move" slot-scope="id, row, index">
        <template>
          <a v-show="((queryParams.pageNum - 1) * queryParams.pageSize + index + 1) != 1" @click="onMove(id,1)">前移</a>
          <span v-show="((queryParams.pageNum - 1) * queryParams.pageSize + index + 1) != 1 && ((queryParams.pageNum - 1) * queryParams.pageSize + index + 1) != total"> | </span>
          <a v-show="((queryParams.pageNum - 1) * queryParams.pageSize + index + 1) != total" @click="onMove(id,2)">后移</a>
          <a v-show="total == 1">-</a>
        </template>
      </template>

      <!-- 发布状态 -->
      <template
        slot="publishFlagText"
        slot-scope="item"
      >
        <span :class="item == '已上架' ? 'state_color_green' : 'state_color_red'">{{item}}</span>
      </template>

      <!-- 操作 -->
      <template slot="operation" slot-scope="item,row">
        <span class="blueText">
          <span @click="onStop(row)">{{item.publishFlag == 1 ? '下架' : '上架'}} | </span>
          <span @click="toDetail(2,item.id)">编辑</span> |
          <span @click="deleteFilling(item.id)">删除</span>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model="isDelete"
      width='400px'
      title="删除"
      @ok="deleteOk"
      :centered='true'
      :closable='false'
    >
      <div class="tips">{{isBuy?'当前的学习包已有机构购买，该学习包不可删除。':'是否确认删除该学习包？'}}</div>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "学习包名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "学习包单价",
    align: "center",
    dataIndex: "couponPrice",
  },
  {
    title: "发布状态",
    align: "center",
    dataIndex: "publishFlagText",
    scopedSlots: {
      customRender: "publishFlagText",
    },
  },
  {
    title: "终端展示",
    align: "center",
    dataIndex: "id",
    scopedSlots: {
      customRender: "move",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    HeaderBox,
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      isBuy: false,
      loading:false,
      isDelete: false,
      columns,
      tableData:[],
      total: 0,
      queryParams: {
        name:'',
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
      packageId:'',

    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },
    // 高级检索
    onSearch(){
      this.queryParams.pageNum = 1
      this.getManageList()
    },
    toDetail(operation, id) {
      this.$router.push({ path: "/admin/Mechanism/LearnPackageDetail", query: { operation:operation,id: id } })
    },
    deleteOk(e) {
      this.isDelete = false;
      this.deleteData(this.packageId)
    },
    deleteFilling(id) {
      this.packageId = id
      this.isDelete = true;
    },
    // 控制 前移 后移
    onMove(id,type){
      this.loading = true
      this.$ajax({
        method: 'put',
        url:"/hxclass-management/organiz-package/edit-sort?id=" + id + '&type=' + type,
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 控制 上/下架
    onStop(e){
      this.loading = true
      this.$ajax({
        method: 'put',
        url:"/hxclass-management/organiz-package/publish?id=" + e.id + '&publishFlag=' + (e.publishFlag ? 0 : 1),
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 删除标签
    deleteData(id) {
      this.$ajax({
        url: '/hxclass-management/organiz-package/del?id=' + id,
        method: "PUT",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('删除成功');
          this.getManageList()
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 查询列表
    getManageList(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/organiz-package/list",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList()
  },
  // 生命周期-实例挂载后调用
  mounted() {
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  min-height: 100%;

  .ant-select {
    width: 124px !important;
  }

  .btn {
    margin: 0 24px 14px 0;
  }

  .exam-name {
    margin-top: 16px;

    .tag {
      margin-right: 2px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
    }

    /deep/.ant-select {
      width: 240px !important;
    }
  }
}
.class-box {
  display: flex;
  justify-content: space-between;
  .classify {
    width: 30%;
    height: 100px;
    border: 1px solid #666666;
    color: #000000;
    line-height: 100px;
    text-align: center;
  }
  .classblue {
    border-color: #2b6ed4;
    color: #2b6ed4;
  }
}
.tips {
  font-size: 16px;
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-title {
  color: #000000;
  text-align: center;
}
/deep/.ant-modal-header {
  border-bottom: none;
}
/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}
/deep/ .ant-modal-body {
  padding: 14px 24px 14px;
}
</style>
